.odrBraking-chart {
  padding: 10px 10px 25px 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px;
  margin-top: 30px;

  h3 {
    opacity: 0.85;
    margin-bottom: 5px;
  }
  h5 {
    opacity: 0.85;
    margin-bottom: 15px;
  }
}

.odrBraking-header {
  width: 100%;
  display: flex;
  margin-bottom: 24px;
  flex-direction: row;
  justify-content: space-between;
}

.odrBraking-popConfirm {
  font-size: 14px;
  display: flex;
  justify-content: flex-end;

  span {
    font-weight: 450;
  }
}
.odrBraking-tag {
  height: 21px;
}
