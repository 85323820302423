th:hover .vt-sort-btn {
  background: #f2f2f2;
}

th .vt-sort-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 60px;
  background-color: #fafafa;
  background-color: none;
  cursor: pointer;
  svg {
    font-size: 10px;
    fill: #bfbfbf;
  }
  .icon-active {
    svg {
      fill: #1890ff;
    }
  }
  &:hover {
    background-color: #1890ff;
    color: #fff;
    svg {
      fill: #fff;
    }
  }
}
