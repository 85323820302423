@import './styleVariables.scss';

a {
  color: $primary-active;
}

.ant-popover-content {
  position: relative;
  top: -10px;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
}

// antd radio group overwrite:
.ant-radio-group {
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    background-color: $primary-active;
    color: #fff;
    border-color: $primary-active;
  }
  .ant-radio-button-wrapper {
    border: 1px solid #bdbdbd;
  }
  .ant-radio-button-wrapper:first-child {
    border-left: 1px solid #bdbdbd;
    border-radius: 4px 0 0 4px;
  }
  .ant-radio-button-wrapper:last-child {
    border-left: 1px solid #bdbdbd;
    border-radius: 0 4px 4px 0;
  }
}
