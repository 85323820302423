$wheelOk: #333;
$wheelOldData: #666;
$wheelError: tomato;
$wheelErrorOldData: #fb9886;

.tire-micro-view {
  &__tire {
    width: 20px;
    height: 8px;
    border-radius: 4px;
    background: $wheelOk;

    &--twin {
      height: 5px;
    }

    &.error {
      border-color: $wheelError;
      background: $wheelError;
    }

    &.old-data {
      background: $wheelOldData;
      border-color: $wheelOldData;
    }

    &.error.old-data {
      border-color: $wheelErrorOldData;
      background: $wheelErrorOldData;
    }
  }
}
