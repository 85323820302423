.tf-right-drawer {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #e9edee;

  .virtual-table-container {
    flex: 1;
    overflow: hidden;

    .alarm-icon {
      svg {
        position: relative;
        bottom: 18px;
      }
      &.generic-places-alarm svg {
        bottom: 0;
      }
    }
  }
  .trailer-info-panel {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100%;
  }

  &.hidden {
    display: none;
  }
}
.livemapv2-right-drawer-trailer {
  &.hidden {
    display: none;
  }
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  .trailer-info-panel {
    background-color: white;
  }
  .trailer-details-container {
    padding: 16px;
    overflow-x: hidden;
    overflow-y: scroll;
    .ant-page-header {
      position: fixed;
    }
  }
}
