@import '../../scss/styleVariables.scss';

.ody-toggle-btn {
  box-shadow: none;
  border: 1px solid #bdbdbd;
  padding: 8px 15px;
  max-height: 36px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  line-height: 20px;
  border-radius: 4px;
  letter-spacing: 0.5px;
  margin: 0 5px;

  background-color: #fff;
  color: $text-primary;

  &.disabled {
    cursor: not-allowed;
  }
  &:hover.enabled {
    cursor: pointer;
    background-color: #f5f5f5;
    color: $text-primary;
  }
  &.checked {
    background-color: $primary-active;
    color: #fff;
  }
  .ody-btn-icon {
    margin-right: 8px;
  }
}
