@import '../../scss/styleVariables.scss';

.application-settings {
  min-width: 820px;
  .application-settings-div {
    padding: 12px;
    background-color: white;
    height: 50vh;
    overflow: auto;
    .description {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      .description-item {
        display: flex;
        flex-direction: row;
      }
      .exclamation-circle-icon {
        color: $info-colour;
        padding-right: 5px;
        line-height: 25px;
      }
    }
    .not-expandable .ant-table-row-expand-icon {
      display: none;
    }
  }
  .options-cell {
    display: flex;
    flex-direction: row;
    .option-div,
    .warning-div {
      width: 50%;
      padding: 5px;
      display: flex;
      align-items: center;
    }
    .ant-btn:hover,
    .ant-btn:focus {
      color: $primary-action-blue;
      border-color: $primary-action-blue;
    }
  }
}

.setting-dropdown-menu {
  max-height: 50vh;
  overflow: auto;
}

.application-version {
  display: flex;
  flex-direction: row;
  padding-left: 49px;
  border-bottom: 1px solid #f0f0f0;
  height: 80px;
  justify-content: center;
  .version-title {
    width: 30%;
    padding: 16px;
  }
  .version-number {
    width: 70%;
    padding: 21px;
  }
}
.ant-modal-footer {
  .ant-btn {
    border-radius: 24px;
  }
  .ant-btn-primary {
    background-color: $primary-active;
    border-color: $primary-active;
    &:hover {
      background-color: $primary-hover-blue;
    }
  }
  .ant-btn-default:hover {
    color: $primary-active;
    border-color: $primary-active;
  }
}
