.wheels-layout-group {
  display: inline-block;
}
.wheels-layout-info-icon {
  vertical-align: super;
  font-size: 13px;
}

$yNamePosition: -7px;
$wheelOk: #333;
$wheelOldData: #666;
$wheelError: tomato;
$wheelErrorOldData: #fb9886;
$wheelSelected: #1890ff;

.wheel-layout {
  display: flex;
  flex-direction: row;
  margin: 10px 10px 10px 50px;
  padding-left: 100px;
  background: #ddd;
  position: relative;

  &:before {
    content: ' ';
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    left: -37px;
    border-right: 30px solid #ddd;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 100%;
    width: calc(100% - 34px);
    height: 4px;
    background: #999;
    top: calc(50% - 2px);
    left: -30px;
  }

  &__axle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 180px;
    width: 120px;
    margin: 0 10px;
    position: relative;

    &--twin {
      height: 250px;
    }

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      width: 4px;
      height: 100%;
      background: #999;
      top: 0;
      left: calc(50% + 2px);
      z-index: 0;
    }

    &__tires {
      position: relative;
      z-index: 1;

      &--left {
        margin-bottom: -10px;
      }

      &--right {
        margin-top: -10px;
      }
    }

    &--selected-only {
      .wheel-layout__axle__tire {
        //opacity: 0.5;
      }
    }

    &__tire {
      width: 100%;
      height: 43px;
      background: $wheelOk;
      margin: 5px;
      border-radius: 10px;
      position: relative;
      display: flex;
      align-items: flex-end;
      border: 3px solid #333;
      cursor: pointer;

      &:after,
      &:before {
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 4px;
        background: #999;
        opacity: 0.1;
        top: calc(50% - 10px);
        left: 0;
        z-index: 0;
      }

      &:before {
        top: calc(50% + 5px);
      }

      &.error {
        border-color: $wheelError;
        background: $wheelError;
      }

      &.old-data {
        background: $wheelOldData;
        border-color: $wheelOldData;
      }

      &.error.old-data {
        border-color: $wheelErrorOldData;
        background: $wheelErrorOldData;
      }

      &.selected,
      &.error.old-data.selected {
        border-color: $wheelSelected;
      }

      &__info {
        color: #fff;
        font-size: 11px;
        display: flex;
        justify-content: space-around;
        text-align: center;
        padding: 4px 0;
        flex: 1;
        z-index: 1;
      }

      &__name {
        font-size: 10px;
        display: inline-block;
        position: absolute;
        top: $yNamePosition;
        left: calc(50% - 15px);
        width: 30px;
        border-radius: 3px;
        background: #666;
        color: #fff;
        padding: 0 1px;
        text-align: center;
        z-index: 1;
      }

      &--left-1,
      &--left-2 {
        .wheel-layout__axle__tire__name {
          top: auto;
          bottom: $yNamePosition;
        }
      }

      &__alert {
        font-size: 12px;
        margin-top: -2px;

        .warning-icon {
          margin: 0;
          font-size: inherit;
        }
      }
    }

    &--twin {
      .wheel-layout__axle__tire {
        align-items: flex-end;

        &--right-1,
        &--left-2 {
          align-items: flex-start;

          .wheel-layout__axle__tire__name,
          .wheel-layout__axle__tire__alert {
            top: auto;
            bottom: $yNamePosition;
          }
        }
        &--left-1 {
          .wheel-layout__axle__tire__name {
            top: $yNamePosition;
            bottom: auto;
          }
        }
      }
    }

    &--single {
      .wheel-layout__axle__tire {
        &--left-1 {
          align-items: flex-start;
        }
      }
    }
  }

  &__tooltip {
    font-size: 12px;
    p {
      margin: 0;
      padding: 0;
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 5px 0;
    }

    &__item {
      display: flex;
      &__wheel {
        display: inline-block;
        width: 25px;
        height: 15px;
        background: $wheelOk;
        border-radius: 5px;
        position: relative;
        align-items: flex-end;
        cursor: pointer;
        margin: 2px 5px 0 0;
        border: 2px solid #999;
        padding: 0;
        &--issue {
          background: $wheelError;
        }
        &--old-data {
          background: $wheelOldData;
        }
        &--issue-old-data {
          background: $wheelErrorOldData;
        }
      }
      &__text {
        flex: 1;
      }
    }
  }
}
