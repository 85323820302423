@import '../../../../../../scss/styleVariables.scss';

.anomaly {
  color: $tag-warning-color;
}

.outage {
  color: $tag-error-color;
}

.good {
  color: $tag-success-color;
}

.unknown {
  color: $grote-lamp-unknown-color;
}
