.alert-map-context {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.trailer-history-header-items {
  display: flex;
  margin-left: 16px;
}
