@import '../../scss/styleVariables.scss';

.ody-text-btn {
  box-shadow: none;
  cursor: pointer;
  border: none;
  padding: 8px 15px;
  max-height: 36px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  line-height: 20px;
  border-radius: 24px;
  letter-spacing: 0.5px;
  background-color: #ffffff00;
  margin: 0 5px;
  color: $primary-active;
  &:hover {
    background-color: rgba(0, 112, 180, 0.04);
  }
  &:active {
    background-color: $nav-active-background;
  }
  &.disabled {
    color: #9e9e9e;
    cursor: default;
    &:active {
      background: none;
    }
    &:hover {
      background: none;
    }
  }
  .ody-btn-icon {
    margin-right: 8px;
    svg {
      width: 14px;
      height: 14px;
    }
  }
  &.primary-btn {
    background-color: $primary-active;
    border-color: $primary-active;
    color: #fff;
    &:hover {
      background-color: lighten($primary-active, 10%);
    }
  }
}
