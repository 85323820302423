@import '../../scss/styleVariables.scss';

.layout-header {
  background: #fff;
  padding: 0;
  display: flex;
  z-index: 1;
  justify-content: space-between;
  height: 55px;
  line-height: 100%;
  box-shadow: none;
  border-bottom: 1px solid #e0e0e0;
  color: #0070b4;
  .layout-header__title {
    color: #000;
    font-size: 18px;
    letter-spacing: 0.15px;
    padding-top: 2px;
  }

  @media screen and (max-width: $screen-size-tablet) {
    height: 48px;
    line-height: 48px;
  }

  &__title {
    margin: 0;
    @media screen and (max-width: $screen-size-tablet) {
      font-size: 1.3em;
    }
  }
}

.trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 40px;
  width: 40px;
  margin: auto 16px auto 8px;
  cursor: pointer;
  border: none;
  background: none;
  transition: color 0.3s;
  border-radius: 50%;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-property: background-color;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0ms;
  &:hover {
    color: #0070b4;
    background-color: rgba(0, 112, 180, 0.04);
  }
  @media screen and (max-width: $screen-size-tablet) {
    line-height: 48px;
  }
}

.header-menu-left {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  height: auto;

  .sidebar-logo {
    align-self: center;
    max-height: 100%;
    line-height: 100%;
  }
}

.header-menu-right {
  display: flex;
  align-items: center;
  margin-right: 16px;

  .antd-pro-notice-icon-badge {
    top: 2px;
    font-size: 24px;
  }

  &__icon-wrap {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 2px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-left: 0;
    }
  }
}
