.google-icon {
  padding: 0 5px;
}

.trailer-event-context-drawer {
  transition: all 1s ease-in-out;
  .trailer-history-description-floating-pane {
    top: 20px;
  }
}

.error-event-context-drawer {
  margin-right: 30%;
}
.trailer-history-description {
  position: static;
  font-size: 12px;
  background: white;
  color: black;
  padding: 16px;

  .flex-box-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .event-description {
      min-width: 200px;
      flex-grow: 1;
      width: fit-content;
      margin: 0 5px 20px 0;
    }

    .error-box {
      height: fit-content;
      width: fit-content;
      min-width: 300px;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 10px 15px 5px 15px;
    }
  }

  .no-gps-text {
    margin-top: 40px;
  }
}

.event-drawer-context-flex-box {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.event-drawer-context {
  height: 100%;
}

.trailer-event-context-drawer {
  &__panels {
    position: relative;
    height: 100%;
  }
  &__map-container {
    display: flex;
    flex-direction: column;
  }
}
