.report-list-content {
  padding: 40px 50px;
  height: 100%;
  background-color: #fff;
  h1 {
    font-size: 26px;
  }
  h1.rprt-space-title {
    margin-top: 60px;
  }
}

.reports-processing-msg {
  margin-top: 30px;
  & span {
    display: block;
  }
}

.reports-li-processing-msg {
  height: 32px;
}

h5.ant-typography.margin-bottom-zero {
  margin-bottom: 0px;
}
