.sortable-list {
  padding-inline-start: 0;

  &__row {
    padding: 8px;
  }

  &__col {
    &--checkbox {
      width: 15%;
      text-align: center;

      &--item {
        margin-top: 5px;
      }
    }

    &--name {
      width: 70%;
      text-align: left;
      padding-left: 25px;

      &--item {
        margin-top: 4px;
      }
    }

    &--move {
      width: 15%;
      text-align: center;

      &--item {
        margin-top: 3px;
      }
    }
  }
}
