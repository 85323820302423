@import '../../scss/styleVariables.scss';

.layout-slider {
  z-index: 10;
  border-right: 1px solid #e0e0e0;
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    transition-property: none;
    height: 40px;
    margin-bottom: 4px;
    &:first-child {
      margin-top: 16px;
    }
    .anticon {
      color: $primary-body-text;
    }
    .ant-menu-title-content {
      color: $primary-header-text;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover {
    background-color: $nav-hover-background;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:active,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:focus {
    border-right: none;
    &::after {
      border-right: none;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:active {
    border-right: none;
    background-color: $nav-active-background;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    .anticon,
    .ant-menu-title-content {
      color: $primary-active;
    }
    a {
      color: #333333;
    }
    &::after {
      border-right: none;
    }
  }
}

.layout-slider.ant-layout-sider-collapsed,
.ant-menu-inline-collapsed {
  @media screen and (max-width: $screen-size-tablet) {
    width: 0 !important;
    max-width: 0 !important;
    min-width: 0 !important;

    .ant-menu-item {
      padding: 0 !important;
      text-align: center;
    }
  }
}
.navigation-menu.ant-menu > .ant-menu-item {
  .ant-menu-item-icon {
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
    .anticon {
      font-size: 20px;
      width: 20px;
      height: 20px;
    }
  }
  .ant-menu-title-content {
    margin-left: 16px;
    font-size: 16px;
  }
}
