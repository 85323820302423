.y-axis-event-timeline,
.y-axis-recorded-event-timeline {
  path {
    display: none;
  }

  line {
    stroke: #f0f0f0;
    stroke-width: 5px;
    stroke-linecap: round;
  }

  text {
    font-family: 'roboto';
    font-size: 14px;
  }
}

.x-axis {
  text {
    font-family: 'roboto';
    font-size: 12px;
  }
}

.recorded-event-label,
.dtc-label {
  font-family: 'roboto';
  font-size: 14px;
  font-weight: 600;
}

div.event-timeline-tooltip {
  max-width: 400px;
}

.read-more-text {
  float: right;
  padding-top: 5px;
}
