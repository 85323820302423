.histogram-chart {
  padding: 10px 10px 25px 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px;
  margin-top: 30px;

  h3 {
    opacity: 0.85;
    margin-bottom: 5px;
  }
  h5 {
    opacity: 0.85;
    margin-bottom: 15px;
  }
}

.missing-asset {
  opacity: 0.65;
  display: inline-block;
}

.asset-path {
  stroke: #cacaca;
  stroke-width: 3;
  &:hover {
    stroke: #7abdfb;
    stroke-width: 4;
  }
}

.line-tooltip .ant-tooltip-arrow {
  display: none !important;
}

.point-text {
  text-align: right;
}

.hist-x-container {
  .hist-x-axis {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }
  .hist-x-label {
    display: flex;
    justify-content: space-around;
    font-size: 12px;
  }
  display: flex;
  flex-direction: column;
}

.hist-y-container {
  .hist-y-axis {
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    text-align: right;
  }
  .hist-y-label {
    .hist-y-label-text {
      transform: rotate(270deg);
      font-size: 11px;
      transform-origin: 0 0;
      max-height: 40px;
      overflow: hidden;
    }
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
  }
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  height: 100%;
}
