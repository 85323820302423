.dashboard-title-menu {
  padding: 14px 14px 8px 14px;
  display: flex;
  justify-content: space-between;
  .window-size-label {
    margin-right: 14px;
  }
  .control-button-wrap {
    display: flex;
    align-items: center;
  }
}
