.event-drawer-context-alert {
  &__details {
    margin-top: 5px;
    @media (min-width: 1280px) {
      display: flex;

      &__item {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
