@import '../../scss/styleVariables.scss';
.home {
  &__logo {
    max-height: 167px;
    max-width: 356px;
    position: relative;
    top: calc(50% - 120px);
    @media screen and (max-width: $screen-size-tablet) {
      max-width: 90%;
      height: auto;
    }
  }
}

.version-style {
  position: absolute;
  bottom: 5px;
  right: 0px;
  cursor: default;
  border: 0;
  background: transparent;
  color: $primary-body-text;
  font-size: 12px;
  width: 100%;
  margin: 0;
  text-align: left;
}
.ant-layout-sider-collapsed {
  .version-style {
    text-align: center;
  }
  .ant-menu-item .ant-menu-title-content {
    display: none !important;
  }
}

.default-background {
  text-align: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.tip-background {
  text-align: center;
  height: 100%;
  background-color: #fdfdfd;
}
