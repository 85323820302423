.installation {
  padding-left: 8px;
  margin-bottom: 8px;
}

.installation-details {
  margin-bottom: 16px;
  display: flex;
}

.rf-detail {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.rf-labels {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  margin-top: 2px;
}

.tire {
  width: 18px;
  height: 50px;
  background-color: black;
  border-radius: 4px;
  opacity: 0.8;
}
