.trailer-info-panel__title_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 14px;
  margin-top: 16px;
  margin-bottom: 8px;
  .arrow-left-btn {
    background: none;
    border: none;
    margin-right: 8px;
    margin-left: 0;
    color: #000;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: #40a9ff;
    }
  }
}
.trailer-info-panel {
  span.sub-title {
    margin-left: 8px;
    color: #8c8c8c;
    font-size: 14px;
    line-height: 22px;
    vertical-align: middle;
    font-weight: 400;
  }
  &__header {
    position: relative;
    padding-right: 14px;

    &__timeframe__item {
      width: 35px;
      text-align: center;
    }

    .ant-page-header-heading-sub-title {
      position: absolute;
      right: 0;
    }
    &__datepicker {
      min-width: 0 !important;
      width: 0 !important;
      overflow: hidden;

      &.ant-picker {
        order: 2;
        width: 0;
        padding: 0;
        border: 0;
      }
    }
    &__date-selector {
      position: absolute;
      right: 20px;
      bottom: -40px;
      z-index: 2;

      &__info {
        width: auto;
        &--selected {
          width: 220px;
        }
      }
    }
    &__datepicker__date {
      position: relative;

      &__bubble {
        display: inline-block;
        background: #1890ff;
        position: absolute;
        right: 50%;
        top: 50%;
        border-radius: 50%;
      }
    }
    &__datepicker__legend__loading {
      text-align: center;
    }
    &__datepicker__legend__bubble {
      display: inline-block;
      background: #1890ff;
      border-radius: 50%;
      margin-right: 2px;

      &.min {
        width: 8px;
        height: 8px;
        opacity: 0.5;
      }
      &.mid {
        width: 16px;
        height: 16px;
        opacity: 0.5;
      }
      &.max {
        width: 24px;
        height: 24px;
        opacity: 0.5;
      }
    }
  }
  &__title {
    padding: 10px 10px 0;
  }

  &__component {
    border-top: 1px solid rgb(217, 217, 217);
    padding: 10px;
  }
}
.ant-calendar-picker-container {
  color: rgba(0, 0, 0, 0.85);
  z-index: 999;
}
.ant-calendar-date {
  color: rgba(0, 0, 0, 0.85);
}

.ant-calendar-footer-extra {
  padding: 0 12px;
  margin: 0 -12px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.85rem;

  > span {
    display: flex;
    align-items: center;
  }
}

.ant-calendar-next-month-btn-day,
.ant-calendar-last-month-cell {
  .trailer-info-panel__header__datepicker__date__bubble {
    display: none;
  }
}
