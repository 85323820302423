@import '../../scss/styleVariables.scss';

.user-menu {
  .user-menu-menu-item {
    align-items: center;
    margin: $large-margin;

    &:hover {
      cursor: pointer;
      color: $info-colour;
    }
  }

  .sub-icon-menu-fix {
    margin-top: -4px;
  }

  a.user-menu-menu-item {
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: $large-margin;
    color: $text-colour;

    &:hover {
      cursor: pointer;
      color: $info-colour;
    }
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  }

  .user-menu-display-name {
    color: lightgray;
    font-size: 12px;
  }
  .user-menu__avatar-wrap {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .user-menu__avatar {
    align-self: center;
    cursor: pointer;
    height: 32px;
    width: 32px;

    @media screen and (max-width: $screen-size-tablet) {
      height: 30px;
      line-height: 30px;
      width: 30px;
      font-size: 16px;
    }
  }

  &.ant-popover {
    padding: 0px;

    .ant-popover-content {
      .ant-popover-arrow {
        display: none;
      }
      .ant-popover-inner .ant-popover-inner-content {
        padding: 0px;
      }
    }
  }
}
