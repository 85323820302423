@import '../../scss/styleVariables.scss';

.grote-guardian-visualisation-container {
  .green {
    fill: $grote-lamp-good-color;
  }

  .red {
    fill: $grote-lamp-outage-color;
  }

  .yellow {
    fill: $grote-lamp-anomaly-color;
  }

  .grey {
    fill: $grote-lamp-unknown-color;
  }

  .lamp-hover {
    stroke: $grote-trailer-lamp-highlight-color;
    stroke-width: 2;
    stroke-opacity: 100%;
  }

  .lamp-trailer-light-hover {
    fill: $grote-trailer-lamp-highlight-color;
  }
}
