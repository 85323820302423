.user-dropdown {
  .ody-btn {
    width: fit-content;
    align-self: center;
  }
  .ody-btn,
  .ody-text-btn {
    margin: 4px 0px;
    a,
    a:hover,
    a:active,
    a:focus {
      color: inherit;
    }
  }
  .user-dropdown-content {
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .user-dropdown-avatar,
  .user-dropdown-company {
    padding: 8px 0px;
  }

  .ant-divider.ant-divider-horizontal {
    margin: 0px;
  }
}
