$border-grey: rgba(0, 0, 0, 0.12);
$dark-text: rgba(0, 0, 0, 0.81);
$disabled-text-grey: #9e9e9e;
$active-text-blue: #0070b4;
$active-background-blue: rgba(0, 112, 180, 0.08);
$hover-background-grey: rgba(0, 0, 0, 0.07);
$hover-button-blue: #00588d;
$box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);

.toolbox {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 100;

  &__display-information {
    position: absolute;
    left: 40px;
    background: #fff;
    box-shadow: $box-shadow;
    &__header {
      padding: 0.5rem 1rem;
      text-align: center;
      white-space: nowrap;
      border-bottom: 1px solid $border-grey;
      font-weight: 700;
      color: #000000;
    }
    &__button {
      border: 0;
      display: block;
      width: 100%;
      text-align: left;
      color: $dark-text;
      &:hover {
        background: $hover-background-grey;
        color: $dark-text;
      }
      &:focus {
        color: $dark-text;
      }
      &.active {
        background: $active-background-blue;
        svg {
          fill: $active-text-blue;
          color: $active-text-blue;
        }
      }
    }
  }
  &__reset-information {
    &__button {
      color: $disabled-text-grey;
      width: 100%;
      text-align: left;
      display: block;
      border-radius: 0;
      border-width: 1px 0 0 0;
      border-color: $border-grey;
      &:hover,
      &:focus {
        color: $disabled-text-grey;
        border-color: $border-grey;
      }
      &.active {
        color: $active-text-blue;
        &:hover {
          background: $hover-background-grey;
        }
      }
    }
  }
  &__button-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    box-shadow: $box-shadow;

    &__button {
      border-radius: 0;
      padding: 0;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      transition: none;

      svg {
        width: 24px;
        height: 24px;
        fill: $active-text-blue;
      }

      &.border-bottom {
        border-bottom: 1px solid $border-grey;
      }

      &:hover {
        background: $hover-button-blue;

        svg {
          fill: #fff;
        }
      }
      &.active,
      &:active {
        background: $active-text-blue;
        svg {
          fill: white;
        }
      }
    }
  }
}
