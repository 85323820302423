@import './styleVariables.scss';

@mixin generic-tooltip {
  position: absolute;
  border-radius: 3px;
  padding: 7px 10px;
  font-size: 12px;
  background: white;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  text-align: left;
  z-index: 1001;
}

@mixin generic-trailer-history-floating-pane {
  font-size: 12px;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  border-radius: 10px;
}

@mixin typography-base {
  font-style: normal;
}

@mixin typography-body1 {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
}

@mixin typography-small {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0em;
}

@mixin typography-semibold {
  font-weight: 600;
}

@mixin typography-light {
  font-weight: 400;
  color: $primary-body-text;
}
