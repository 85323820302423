@import '../../scss/mixins.scss';
@import '../../scss/styleVariables.scss';

.trailerfit-layout.ant-layout {
  height: 100vh;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: $large-margin;
}

.sidebar-logo {
  display: block;
  text-align: center;
  img {
    min-height: 25px;
    max-height: 40px;
    max-width: 120px;
    margin: 0px 16px 0px 0px;
  }
}

.loading-modal .ant-modal-content {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.centred {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
