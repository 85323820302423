$warning-alarms: ebsAmber, groteLightAnomaly;
$error-alarms: ebsRed, groteLightOutage;

.tf_dashboard__buttons_li button {
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  background: transparent;
  cursor: pointer;
  height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  margin: 0 5px 5px 0;

  .tf_icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 8px;
    svg {
      height: 20px;
      width: 20px;
      position: relative;
      top: -1px;
      fill: #595959;
    }
  }

  &:hover {
    background: #f2f2f2;
  }
  &:focus {
    outline: 0;
  }
  &.active {
    background: #1890ff;
    color: #fff;
    .tf_icon {
      svg {
        fill: #fff;
      }
    }
  }
}

.tf_dashboard__buttons_li {
  @each $warning in $warning-alarms {
    &.tf-icon-#{$warning} {
      button {
        .tf_icon {
          svg {
            fill: #ff8f00;
          }
        }
        &.active {
          .tf_icon {
            svg {
              fill: #ffe88c;
            }
          }
        }
      }
    }
  }

  @each $error in $error-alarms {
    &.tf-icon-#{$error} {
      button {
        .tf_icon {
          svg {
            fill: #b00920;
          }
        }
        &.active {
          .tf_icon {
            svg {
              fill: #ffacac;
            }
          }
        }
      }
    }
  }
}
