@import '../../scss/styleVariables.scss';
@import '../../scss/mixins.scss';

.page-error {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
}

.page-error-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 560px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.info-icon-outline {
  width: 96px;
  height: 96px;
  margin-bottom: 24px;
  position: relative;

  .info-icon-background {
    background-color: $border-error;
    opacity: 8%;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 50%;
  }

  .info-icon {
    color: $icon-error-colour;
    z-index: 1;
    position: absolute;
    top: 25%;
    left: 25%;
    font-size: 46px;
  }
}

.page-error-header {
  font-weight: 400;
  color: $primary-header-text;
  font-size: 16px;
  line-height: 21.79px;
  letter-spacing: 0.5px;
  text-align: center;
}

.page-error-body {
  margin-top: 4px;
  margin-bottom: 24px;
  @include typography-light;
  font-size: 14.22px;
  line-height: 19.37px;
  letter-spacing: 0.25px;
  text-align: center;
  padding: 0 20px;
}
