.button-container {
  width: 92px;
  padding: 4px;
  cursor: pointer;
  text-align: center;

  .ant-btn-primary {
    background-color: #0070b4;
    fill: white;
  }

  .ody-nav-btn-text {
    padding-top: 5px;
  }
}
