@import '../../scss/styleVariables.scss';

.bar-container {
  .bar-outline {
    .bar-contents {
      height: 7px;
      border-radius: 20px;
      margin: 2px;
      margin-left: 2px;
      margin-right: 2px;
    }
    .empty {
      background-color: $border-success;
    }
    .safe {
      background-color: $border-success;
    }
    .warning {
      background-color: $border-warning;
    }
    .danger {
      background-color: $border-error;
    }
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 3px;
    border: 1px #aaa solid;
    width: calc(100% - 4px);
    height: 11px;
    border-radius: 20px;
  }
  .bar-title {
    margin-left: 5px;
  }
  position: relative;
  max-width: 130px;
  min-width: 20px;
}

.ant-table-thead > tr > th .ant-table-header-column {
  overflow: hidden;
  text-overflow: ellipsis;
}

.gohc-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.disabled-row {
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
