@import '../../scss/styleVariables.scss';

.trailer-id-card-content {
  &__tabs {
    @media screen and (max-width: $screen-size-tablet) {
      .ant-tabs-left-bar,
      .ant-tabs-left-content {
        float: none;
        width: 100%;
        padding: 0;
      }
      .ant-tabs-left-bar {
        .ant-tabs-tab {
          text-align: center;
          border-bottom: 1px solid #e8e8e8;
          padding: 5px 0;
          margin: 0;
        }
      }
      .ant-tabs-left-content {
        padding: 1rem;
      }
    }

    &__tab {
      padding: 16px;
      @media screen and (max-width: $screen-size-tablet) {
        padding: 0;

        .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr {
          td,
          th {
            padding: 0;
          }
        }
      }
    }
  }
}
