@import '../../../../../../scss/styleVariables.scss';

.bar {
  margin-left: 8px;
  margin-right: 8px;
  max-height: 16px;
}

.bar-text {
  align-content: center;
  justify-content: center;
  display: flex;
}
