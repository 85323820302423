@import '../../scss/styleVariables.scss';

.geo-json-tooltip {
  font-style: normal;
  font-weight: 500;
  position: absolute;
  padding: 16px;
  background: linear-gradient(0deg, #383838, #383838), #ffffff !important;
  width: 185px;
  z-index: 10;
  pointer-events: none;
  border-radius: 2px;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.5px;

  .trailer-count {
    display: flex;
    align-items: baseline;
    .tooltip-text {
      padding-left: 5px;
    }
  }

  .trailer-title {
    font-size: 16px;
    padding-bottom: 2px;
  }
  .geo-title {
    font-weight: 400;
    color: #9e9e9e;
  }
  .geo-trailers-list,
  .place-name {
    padding-top: 6px;
  }

  .trailer-name-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: $screen-size-tablet) {
    display: none;
  }
}
