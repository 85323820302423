@import '../../../scss/styleVariables.scss';

.alert-timeline-axis {
  line,
  path {
    stroke: #909090;
  }

  text {
    text-shadow: white -1px -1px 0px, white 1px -1px 0px, white -1px 1px 0px, white 1px 1px 0px;
  }
}

.trailerRowSelected .alert-timeline__chart line {
  stroke: #bfbfbf;
}
.alert-timeline {
  &__chart {
    .chart {
      max-width: 100%;
    }
    line {
      stroke: #f0f0f0;
    }
  }
  @media screen and (max-width: $screen-size-tablet) {
    &__row {
      flex-direction: column;
    }
    &__col {
      width: 100%;
      &--chart {
        position: relative;
      }
      &--filters {
        .notification-type-selector {
          margin: 0 auto;
        }
      }
    }

    &__chart {
      overflow: auto;
      &:after {
        content: ' ';
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -1px;
        box-shadow: -10px 0px 15px rgba(0, 0, 0, 1);
      }
      .chart {
        min-width: 480px;
      }
    }
  }
}
