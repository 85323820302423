@import '../../scss/styleVariables.scss';

.notifications-screen-container {
  background-color: white;
  height: calc(100vh - 55px);
  box-sizing: border-box;
  padding: 0px;
  overflow-y: auto;
  text-align: center;
  scroll-behavior: smooth;
  .history-tabpane {
    text-align: left;
    padding: 16px;
    padding-bottom: 80px;
  }
  .configure-tabpane {
    text-align: left;
    padding: 0px 16px;
  }
  & > .ant-tabs-nav {
    display: none;
  }
  .notification-centre-history .ant-spin-dot.ant-spin-dot-spin {
    position: fixed;
  }
}

/* actionListItem is in commented out component should it be deleted? */
.actionListItem:hover {
  opacity: 1;
}

.event-table-expanded-row {
  white-space: normal;
  pointer-events: none;
}

.event-table-item-description {
  padding-bottom: 20px;
}

.eventType-avatar {
  margin-right: 8px;
}

.notification-configure-table {
  .ant-spin.ant-spin-spinning {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.large-margin-bottom {
  margin-bottom: 16px;
}

.notification-type-selector {
  min-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 5ex;
  .title {
    text-align: center;
  }
  .item {
    flex-grow: 1;
    min-width: 180px;
    width: 33%;
    cursor: pointer;
    display: flex;
    margin: 2px;
    padding: 4px;
    border-radius: 4px;
    .type-name {
      margin: 0 8px;
      width: calc(100% - 40px);
      display: flex;
      align-items: center;
    }
    .right-aligned-icon {
      color: #1890ff;
      position: sticky;
      right: 0px;
      align-self: center;
    }
  }
}

.notification-trailer-selector {
  min-width: 200px;
}

.notification-centre {
  &__pagination {
    text-align: center;
    @media screen and (max-width: $screen-size-tablet) {
      .ant-pagination-item {
        display: none;
      }
      .ant-pagination-item.ant-pagination-item-active {
        display: inline-block;
      }
    }
  }

  &__notification-list {
    padding-bottom: 60px !important;
    li:hover {
      background: #e6f7ff;
      cursor: pointer;
    }

    @media screen and (max-width: $screen-size-phablet) {
      .ant-list-item {
        flex-direction: column;
      }
      .ant-list-item-main {
        order: 0;
        text-align: center;
        display: block;
        width: 100%;
        margin: 0 0 8px 0;
      }
      .ant-list-item-extra {
        padding: 0;
        display: block;
        width: 100%;
        order: 0;
        text-align: center;
        margin: 0;

        img {
          width: 100%;
        }
      }
      .ant-list-item-meta {
        justify-content: center;
        align-items: center;
        .ant-list-item-meta-content {
          flex: initial;
          text-align: left;
        }
        .ant-list-item-meta-title {
          margin-bottom: 0;
        }
      }
    }
  }
}

.list-avatar-read {
  opacity: 0.15;
}

.list-item-read {
  color: #efefef;
}

.no-gps-text {
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.35);
  float: left;
  width: 272px;
}

.sub-settings-tabs {
  & > .ant-tabs-nav > .ant-tabs-nav-wrap {
    justify-content: flex-start;
  }
}

.we-settings-th {
  display: flex;
  justify-content: space-evenly;
}

.sub-settings-email-card {
  padding: 15px 0 20px 20px;
}

.notice-icon {
  svg {
    fill: #0070b4;
  }
}

.alarms-table-cell-container {
  display: flex;
  margin-left: 6px;
  margin-bottom: -8px;
  .ntif-icon {
    position: relative;
    top: 4px;
  }
  .cell-setting-desc {
    margin-left: 20px;
    .cell-setting-title {
      color: black;
      font-weight: 500;
      white-space: nowrap;
    }
    .cell-setting-text {
      width: calc(100vw - 700px);
      display: flex;
      span {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      & > button {
        top: -5px;
      }
      color: rgba(0, 0, 0, 0.7);
    }
  }
}

.li-email-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
