html body {
  font-family: roboto;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

tr.trailerRowSelected,
tr.trailerRowSelected .ant-table-cell-fix-left {
  background-color: #f0f0f0;
}
