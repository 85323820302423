@import '../../scss/mixins.scss';
@import '../../scss/styleVariables.scss';

.event-legend-container {
  width: 50%;

  .event-type-dropdown {
    width: 100%;
  }
}

.event-priority-level-legend.ant-btn-group {
  display: inline-flex;
  flex-wrap: nowrap;
  width: 100%;

  .ant-btn {
    align-items: center;
    flex-grow: 1;
  }
}

.warning-icon {
  margin-left: $large-margin;
  font-size: 32px;
}

.events {
  &__timeline {
    @media screen and (max-width: $screen-size-phablet) {
      display: none;
    }
  }
}

.export-csv {
  padding-left: 15px;
}

.event-list .ant-table-row-cell-last {
  .anticon-filter {
    margin-right: 24px;
  }
}
