@import '../../scss/styleVariables.scss';

.odr-file-download-btn-loading.ant-btn.ant-btn-link {
  color: gray;
}

.axle-details-header {
  margin: 0;
  padding-top: 10px;
}

.trailer-card-id {
  &__or,
  &__odr {
    @media screen and (max-width: $screen-size-tablet) {
      display: none;
    }
  }
  &__header {
    &__actions {
      display: flex;
      justify-content: flex-end;
      flex-flow: row wrap;
      @media screen and (max-width: $screen-size-tablet) {
        justify-content: center;
        button {
          margin: 0 3px 3px !important;
          min-width: 120px;
        }
      }

      &__health {
        .anticon {
          min-width: 14px;
        }
      }
    }
  }
}

.histogram-chart-div {
  width: 650px;
  margin: 5px;
}

.odrBraking-chart-div {
  margin: 5px;
}

.histogram-warning {
  color: tomato;
  margin: 30px;
}

.histogram-list-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  .histogram-switch-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .histogram-switch {
      margin-left: 8px;
    }
  }
}

.histogram-chart-grid {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  border: 1px solid #e8e8e8;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
