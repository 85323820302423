.th-category-dropdown {
  background-color: #fff;
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
}

.th-categories {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  .th-cat-title {
    flex: 1;
  }
}

.ico-has-checks {
  color: #1890ff;
}

.ico-is-open {
  color: white;
}

button.th-dropbtn:hover > .ico-has-checks {
  color: white;
}

th:hover .th-dropbtn {
  background: #f2f2f2;
}

button.th-dropbtn {
  cursor: pointer;
  background: none;
  font-size: 12px;
  border: none;
  color: #bfbfbf;
  background-color: #fafafa;
  width: 24px;
  height: 60px;

  &:hover {
    background-color: #1890ff;
    color: #fff;
  }
  &.is-open {
    background-color: #1890ff;
    color: #fff;
  }
}

.th-dropdown {
  display: inline-block;
  height: 100%;
}

.th-dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  top: 60px;
  background-color: #fff;
  min-width: 160px;
  max-height: 400px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  &.is-open {
    display: block;
    color: #dae8f5;
    .ant-checkbox-wrapper {
      white-space: nowrap;
    }
  }
  button {
    color: black;
    padding: 6px 8px;
    text-decoration: none;
    text-align: left;
    display: block;
    width: 100%;
    background: none;
    border: none;
    .ant-checkbox-wrapper {
      width: 100%;
    }
    &:hover {
      background-color: #ddd;
    }

    &.has-checks {
      background-color: #1890ff;
      color: #dae8f5;
      &:hover {
        background-color: #1477d3;
        color: #fff;
      }
    }
  }
}

.th-dropbtn:hover {
  background-color: #1890ff;
  color: #fff;
  .has-checks {
    color: #fff;
  }
}

.th-cat-btn-container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
}
