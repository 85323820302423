.dayBarChart .axis {
  font: 10px sans-serif;
}

.dayBarChart .axis path,
.dayBarChart .axis line {
  fill: none;
  stroke: rgba(0, 0, 0, 0.54);
  shape-rendering: crispEdges;
}

.dayBarChart .y.axis path {
  display: none;
}

.dayBarChart .y.axis line {
  stroke: black;
  stroke-opacity: 0.03;
}

div.dayBarChartTooltip {
  position: absolute;
  border-radius: 3px;
  padding: 7px 10px;
  font-size: 12px;
  background: white;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 100;
  /*color: #fff;*/
  text-align: left;
  white-space: nowrap;
}
