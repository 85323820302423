.app-sw-options {
  max-width: 320px;
  display: flex;
  flex-direction: row;
  padding: 16px;
}

.admin-icon {
  margin-top: 4px;
}
