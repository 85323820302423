@import '~antd/dist/antd.css';
@import '~ant-design-pro/dist/ant-design-pro.css';
@import './mixins.scss';
@import './styleVariables.scss';

.healthOverlaySummary .ant-card-actions > li,
.live-map-overlay-summary .ant-card-actions > li {
  margin: 2px 0;
}

.reflex-container.vertical > .reflex-splitter {
  border-right: 2px solid #c6c6c6 !important;
  border-left: 2px solid #c6c6c6 !important;

  &:hover,
  .active {
    border-right: 2px solid $info-colour !important;
    border-left: 2px solid $info-colour !important;
  }
}

.mapboxgl-popup {
  z-index: 100;
  user-select: text;
  cursor: text;
}

.generic-tooltip {
  @include generic-tooltip;
}

.stacked-area-y-axis {
  path {
    display: none;
  }

  line {
    stroke-opacity: 0.3;
  }
}

.trailer-context-history-floating-pane {
  @include generic-trailer-history-floating-pane;
  width: calc(100% - 20px);
  z-index: 9;
  bottom: 10px;
  left: 10px;
}

.trailer-history-description-floating-pane {
  @include generic-trailer-history-floating-pane;
  top: 70px;
  left: 16px;
  padding: 16px;
  width: calc(100% - 32px);
  z-index: 10000;
  pointer-events: none;
}

.medium-margin-left {
  margin-left: $medium-margin;
}
.medium-margin-right {
  margin-right: $medium-margin;
}
.medium-margin-top {
  margin-top: $medium-margin;
}
.medium-margin-bottom {
  margin-bottom: $medium-margin;
}

.large-margin-left {
  margin-left: $large-margin;
}
.large-margin-right {
  margin-right: $large-margin;
}
.large-margin-top {
  margin-top: $large-margin;
}
.large-margin-bottom {
  margin-bottom: $large-margin;
}

.small-margin-left {
  margin-left: $small-margin;
}
.small-margin-right {
  margin-right: $small-margin;
}

.full-width {
  width: 100%;
}

.padding2 {
  padding: 2px;
}

.padding20 {
  padding: 20px;
}
.padding-top5 {
  padding-top: 5px;
}

.critical {
  color: $critical-colour;
}

.warning {
  color: $warning-colour;
}

.safe {
  color: $safe-colour;
}

.info {
  color: $info-colour;
}

.list-item-icon {
  width: 20px;
}

.disabled {
  color: $disabled-color;
}

.clickable-text,
.clickable {
  pointer-events: all;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.clickable-text {
  color: $info-colour;
}

.grey-italic-text {
  color: grey;
  font-style: italic;
}

.map-overlay__summary button.ant-btn-icon-only.ant-btn-sm {
  width: 100%;
}

.display-none {
  display: none;
}

.opacity-05 {
  opacity: 0.5;
}

.flex {
  display: flex;
}

.float-right {
  float: right;
}

.alarm-icon {
  border-radius: 50%;
  border: 1.6px solid white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  text-align: center;
  align-self: center;
  min-width: 22px;
  width: 22px;
  height: 22px;
  svg {
    height: 14px;
    width: 14px;
    margin-top: 2px;
  }

  &.red {
    background: $critical-colour;
  }
  &.yellow {
    background: $icon-warning-colour;
  }
  &.blue {
    background: $icon-blue-colour;
    color: #006285;
  }
  &.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.large {
    width: 30px;
    height: 30px;
    border: 2px solid white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    img {
      width: 100%;
      height: 100%;
    }
    svg {
      height: 20px;
      width: 20px;
      margin: 3px 3.5px 0 3px;
    }
  }
}
.ant-tabs.ant-tabs-top .ant-tabs-nav-wrap {
  justify-content: center;
}
.ant-tabs-nav .ant-tabs-tab {
  padding: 12px 16px;
}
.ant-tabs.ant-tabs-left .ant-tabs-tab {
  text-align: right;
  justify-content: flex-end;
}

.typography-bold1-semibold {
  @include typography-base;
  @include typography-body1;
  @include typography-semibold;
}

.typography-bold1-light {
  @include typography-base;
  @include typography-body1;
  @include typography-light;
}

.typography-small-light {
  @include typography-base;
  @include typography-small;
  @include typography-light;
}
