.trailer-history-axis {
  line,
  path {
    stroke: #909090;
  }

  text {
    text-shadow: white -1px -1px 0px, white 1px -1px 0px, white -1px 1px 0px, white 1px 1px 0px;
  }
}

.trailer-info-panel__component--historyFCF {
  position: relative;

  .livemap-fcf-export-button {
    position: absolute;
    left: 10px;
    top: 50px;
  }
}
.livemap-fcf-wrapper__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.livemap-fcf-wrapper__settings__item-label {
  display: inline-block;
  height: 3px;
  width: 25px;
  margin: 0 2px 4px 0;
}
