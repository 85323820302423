.trailer-card-id__content__general-info {
  margin-bottom: 16px;

  &__vehicle-type-title {
    color: rgba(0, 0, 0, 0.43);
  }
  &__vehicle-type-info {
    color: rgba(0, 0, 0, 0.85);
    font-size: 20px;
  }
}

.grote-visualisation-wrapper {
  margin-top: 33px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.description-table {
  .header-cell {
    color: rgba(0, 0, 0, 0.5);
    width: 130px;
  }
}

.info-container {
  padding-left: 8px;
  margin-bottom: 8px;
}
