.trailer-list-drawer .ant-drawer-body {
  padding: 0;
  flex: 1;
  > div:first-child,
  .jetpack-table-container {
    height: 100%;
    .ant-table-wrapper.stickyTable {
      height: inherit !important;
    }
  }
}

#drawerList tr {
  cursor: pointer;
}

.trailer-list-drawer {
  .title-col-container {
    display: flex;
    justify-content: space-between;
    padding: 7px 7px 0 22px;
  }
}
