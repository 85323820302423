.trailer-history-axis {
  line,
  path {
    stroke: #909090;
  }

  text {
    text-shadow: white -1px -1px 0px, white 1px -1px 0px, white -1px 1px 0px, white 1px 1px 0px;
  }
  &.xAxis .domain {
    stroke-dasharray: 8, 8;
  }
}
.places-icon {
  width: 20px;
  height: 20px;
}
