.main-content {
  display: flex;
  align-items: center;
}

.spinner {
  display: flex;
  text-align: left;
  margin-right: 10px;
}
