@import '../../../../scss/styleVariables.scss';

.trailer-map {
  &__selection-tool {
    position: absolute;
    top: 50px;
    right: 8px;
    z-index: 100;
  }

  &__map-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &--selection-tool #deckgl-overlay {
      cursor: crosshair !important;
    }
  }
}

.white-text {
  color: rgba(255, 255, 255, 0.9);
}

.device-drawer tr,
.device-drawer td {
  cursor: pointer;
}

.trailer-list .ant-table-empty .ant-table-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
}

.trailer-list .ant-table-empty {
  .ant-table-header.ant-table-hide-scrollbar,
  .ant-table-body {
    width: max-content;
  }

  .ant-table-body {
    overflow: hidden;
  }

  .ant-table-placeholder {
    width: 100%;
  }
}

.live-map-overlay-summary h6 {
  margin-top: 5px;
  margin-bottom: 0;
}

.trailer-history-header-items {
  display: flex;
  margin-left: $large-margin;
}

.trailer-history-header-item-value-speed {
  text-align: right;
  min-width: 3ex;
}

.trailer-history-header-item-value-axle-load {
  text-align: right;
  min-width: 4ex;
}

.tm-clear-filters-btn {
  z-index: 1;
  position: absolute;
  top: 3%;
  left: 50%;
  margin-top: -10px;

  button {
    background-color: $primary-action-blue;
    border-color: $primary-action-blue;
    position: relative;
    left: -50%;

    &:hover {
      background-color: $primary-hover-blue;
      border-color: $primary-hover-blue;
    }
  }
}
