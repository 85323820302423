//colour
$text-colour: rgba(0, 0, 0, 0.85);
$critical-colour: tomato;
$warning-colour: #ffc107;
$info-colour: #0070b4;
$safe-colour: #87d068;
$disabled-color: #cacaca;

$icon-warning-colour: #fad625;
$icon-blue-colour: #ebf8fe;
$text-primary: #303030;

//size
$small-margin: 4px;
$medium-margin: 8px;
$large-margin: 16px;

//misc
$generic-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

$screen-size-mobile-small: 320px;
$screen-size-mobile-medium: 375px;
$screen-size-mobile: 425px;
$screen-size-phablet: 600px;
$screen-size-tablet: 768px;
$screen-size-desktop-small: 1024px;
$screen-size-desktop: 1440px;
$screen-size-4k: 2560px;

// Odyssey style colors
$primary-active: #0070b4;
$interactive-active: #80b7d9;
$icon-primary: #303030;
$nav-active-background: rgba(0, 112, 180, 0.08);
$nav-hover-background: rgba(0, 112, 180, 0.04);

$tag-neutral-color: #e0e0e0;
$tag-info-color: #a6e2f7;
$tag-success-color: #b7e2c6;
$tag-warning-color: #f7e9b4;
$tag-error-color: #f6c2c9;

$border-success: #30ab5b;
$border-warning: #e8bf29;
$border-info: #00abe7;
$border-error: #dd0c29;

// from: https://www.figma.com/file/UcQeZKhEaO0Q3p59yGNdN8/%E2%9D%96-Components?node-id=15239%3A240502
$icon-error-colour: #b00920;
$primary-header-text: rgba(0, 0, 0, 0.81);
$primary-body-text: rgba(0, 0, 0, 0.54);

$primary-action-blue: #0070b4;
$primary-hover-blue: #00588d;

$grote-lamp-good-color: rgba(48, 171, 91, 0.35);
$grote-lamp-anomaly-color: rgba(232, 191, 41, 0.25);
$grote-lamp-outage-color: rgba(221, 12, 41, 0.25);
$grote-lamp-unknown-color: rgba(211, 211, 211, 0.35);
$grote-trailer-lamp-highlight-color: #268efb;
