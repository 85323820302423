.error-drawer-context {
  &.width-30 {
    width: 30% !important;
    left: auto;
  }
  &.width-100 {
    width: 100% !important;
  }

  .ant-drawer-body {
    height: calc(100% - 55px);
    padding: 5px 10px;
  }

  .error-drawer-context-iframe {
    height: inherit;
    width: 100%;
    border: none;
  }

  .error-drawer-context-no-data {
    padding: 10px;
  }
}
