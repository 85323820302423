@import '../../scss/styleVariables.scss';

.custom-filter-dropdown {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);

  input {
    width: 200px;
    margin-right: $medium-margin;
  }

  button {
    margin-right: $medium-margin;
  }
}

.highlight {
  color: $info-colour;
}

.stickyTable {
  table {
    table-layout: fixed;
  }

  .ant-table-tbody > tr > td {
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    & > * {
      overflow: hidden;
    }
  }

  .ant-table-thead > tr > th {
    white-space: normal;
  }

  .ant-table-cell.jetpack-table-column.ant-table-cell-fix-left-last > span.ant-table-cell-content {
    overflow: hidden;
  }

  .ant-pagination {
    position: fixed;
    right: 5px;
    bottom: -5px;
  }

  .ant-table-empty .ant-table-body {
    height: 0;
  }
}

.ant-table-filter-dropdown {
  min-width: 130px; /* to allow ok/cancel buttons in longer languages */
}

.floating-selector {
  position: absolute;
  pointer-events: none;
  top: 12px;
  right: 3px;
  z-index: 5;
}

.jetpack-table-container {
  padding-right: 2px;
  position: relative;
}

.ant-table-empty .ant-table-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
}
.ant-table-empty .ant-table-header {
  width: max-content;
}
.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 1px;
  height: 100%;
  bottom: 0;
  right: 0px;
  cursor: col-resize;
  z-index: 1;
}
.ant-table-thead:hover .react-resizable-handle {
  background: #ddd;

  &:after,
  &:before {
    content: ' ';
    display: block;
    width: 5px;
    height: 100%;
    position: absolute;
    right: 1px;
    top: 0;
  }
  &:after {
    right: -5px;
    width: 5px;
  }
}

.ant-table-fixed-left .ant-table-body-outer .ant-table-body-inner {
  overflow-y: hidden !important;
}

.ant-table-cell.jetpack-table-column.ant-table-cell-fix-left-last > span.ant-table-cell-content {
  overflow: visible;
}

.ant-table-content > .ant-table-scroll > .ant-table-body {
  height: var(--real-height) !important;
}

.ant-table-body-outer .ant-table-body-inner {
  height: var(--real-height) !important;
}

.ant-table-content > .ant-table-fixed-left > .ant-table-body {
  height: var(--real-height) !important;
}

.ant-table-fixed-left {
  pointer-events: none !important;
  .ant-table-thead {
    pointer-events: all !important;
  }
}

.jetpack-table-column {
  max-width: 80px;
  min-width: 80px;
}

.selection-lock {
  margin-left: 10px;
  &:hover {
    background: #e6f7ff;
    border-radius: 12px;
  }
}
