.th-search {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 60px;
  align-items: center;
  .th-title {
    font-size: 18px;
    padding-left: 8px;
    flex: 1;
    font-weight: 500;
    text-align: left;
    transition: font-size 0.15s ease-out;
    font-size: 14px;
  }
  &.open {
    .th-title {
      font-size: 12px;
      position: absolute;
      top: 2px;
    }
  }

  .th-search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0px;
    font-size: 14px;
    color: #bfbfbf;
    width: 24px;
    height: 60px;
    background-color: none;
    &:hover {
      background-color: #1890ff;
      color: #fff;
    }
  }
}

.th-search-container {
  box-sizing: border-box;
  margin: 0;
  margin-left: 6px;
  margin-top: 10px;
  width: 100%;

  .th-search-input {
    box-sizing: border-box;
    margin: 0;
    height: 30px;
    padding: 4px;
    padding-left: 22px;
    transition: width 0.15s ease-out, opacity 0.1s ease-out;
    width: 0;
    opacity: 0;
    display: none;
    border-radius: 2px;
    font-size: 14px;
    border: 1px solid #98c6f1;
    &:active {
      border: 1px solid #4278ac;
    }
    &:focus-visible {
      border: 1px solid #4278ac;
      outline-color: none;
      outline-width: 0;
    }
  }

  .th-search-icon-in {
    position: absolute;
    display: none;
    opacity: 0;
    bottom: 19px;
    left: 11px;
    font-size: 14px;
    color: #bfbfbf;
  }
  .th-clear-icon {
    display: none;
    opacity: 0;
    font-size: 15px;
    position: absolute;
    bottom: 18px;
    right: 12px;
    color: #bfbfbf;
    &:hover {
      color: #1890ff;
    }
  }
  &.open {
    .th-search-input {
      display: block;
      opacity: 1;
      width: calc(100% - 8px);
    }
    .th-search-icon-in {
      display: block;
      opacity: 1;
    }
    .th-clear-icon {
      display: block;
      opacity: 1;
    }
  }
}

.th-search-btn-container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
}
