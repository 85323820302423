.column-selection {
  height: 500px; // fallback
  height: calc(100vh - 200px);

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .ant-modal-body {
    flex: 1;
    overflow: auto;
  }

  .search-section {
    margin: 10px;
    .search-wrapper {
      display: flex;
      justify-content: center;
      .search-input {
        width: 100%;
        .search-icon {
          color: rgba(0, 0, 0, 0.45);
          cursor: pointer;
        }
      }
    }
  }
  .item-section {
    width: 100%;
    .set-header {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      padding-left: 8px;
      margin-top: 52px;
      display: flex;
      flex-direction: column;
      .sub-header {
        font-size: 14px;
        font-weight: 400;
        opacity: 0.65;
      }
    }
    & .set-top {
      margin-top: 20px;
    }
  }
}

.reg-list-container {
  padding-inline-start: 0;

  .list-row-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    margin: 8px;

    .row-title-wrapper {
      text-align: left;
      padding-left: 25px;
      margin-top: 4px;
      display: flex;
      flex-direction: column;

      .row-subtitle {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .row-title-header {
      text-align: left;
      padding-left: 25px;
    }

    .dynamic-width {
      width: 70%;
    }

    .not-dynamic-width {
      width: 55%;
      flex: 1;
    }

    .handle-element {
      width: 6%;
      text-align: left;
      margin-top: 3px;
    }

    .row-element {
      width: 15%;
      text-align: center;
    }
    .row-input {
      width: 15%;
    }
    .space-top {
      margin-top: 5px;
    }
  }

  .list-row-draggable {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
  }

  .hidden {
    display: none;
  }
}

.sortable-handle {
  opacity: 0.5;
}

.selector-button {
  pointer-events: auto;
}

.sortable-helper {
  .row-title-wrapper {
    text-align: left;
    padding-left: 25px;
    margin-top: 4px;
    display: flex;
    flex-direction: column;

    .row-subtitle {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .row-title-header {
    text-align: left;
    padding-left: 25px;
  }

  .dynamic-width {
    width: 70%;
  }

  .not-dynamic-width {
    width: 55%;
    flex: 1;
  }

  .handle-element {
    width: 6%;
    text-align: left;
    margin-top: 3px;
  }

  .row-element {
    width: 15%;
    text-align: center;
  }
  .row-input {
    width: 15%;
  }
  .space-top {
    margin-top: 5px;
  }
  box-shadow: 1px 1px 2px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background-color: white;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  margin: 8px;
  align-items: center;
  z-index: 10000 !important;
}
