.nodata-loading {
  &__wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 1rem;
  }

  &__figure {
    margin-bottom: 1rem;
  }
  &__icon {
    font-size: 35px;
  }
  &__image {
    height: 70px;
    width: auto;
    flex: 0;
  }
  &__button {
    margin: 0 0.5rem;
  }
}
