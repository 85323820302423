.tf-dashboard {
  position: relative;
  padding: 1rem 1rem 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin-bottom: -20px;
  .tf-dashboard-box {
    flex: 1;
    background: #fff;
    padding: 0.8rem 1rem 0.4rem 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    margin-left: 1rem;
    margin-bottom: 1rem;
    min-width: 200px;
    &.flex-2 {
      flex: 2;
    }
    .box-title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title-container {
        display: flex;
        align-items: center;
      }
      .ant-typography {
        margin-bottom: 0;
      }
    }
    .tf-box-title {
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      margin-left: 1rem;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 12px 0 0 0;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.trailer-count {
  margin-top: 16px;
}
