.score-modal {
  max-height: '80%';
}
h4 {
  margin-top: 24px;
  margin-bottom: 16px;
}
p,
li {
  font-size: 14px;
}
ul,
.wheel-layout__tooltip__list {
  margin-top: 16px;
  margin-bottom: 16px;
}
.title-icon {
  color: #0070b4;
  font-size: 21px;
  &:hover {
    background-color: #1890ff;
    color: #fff;
  }
}

.btn-icon {
  background: none;
  padding: 4px 8px;
  font-size: 12px;
  border: none;
  color: #8c8c8c;
  width: 24px;
  height: 60px;
  &:hover {
    background-color: #1890ff;
    color: #fff;
    cursor: pointer;
  }
}

.health-list-container,
.trailer-list {
  .btn-icon {
    height: 30px;
    width: 30px;
  }
}
