.virtual-table {
  display: block;
  overflow-y: auto;
  overflow-y: auto;
  position: relative;
  border-collapse: collapse;
  border-spacing: 0;
  border-top: 1px solid #f0f0f0;
}

.virtual-table {
  height: 100%;
  table {
    width: 100%;
    margin: -1px 0 0 0;
  }
}

.virtual-table > table > tbody {
  position: relative;
  width: 100%;
  background: #fff;
  font-size: 14px;
}

.virtual-table > table > tbody > tr {
  display: flex;
  box-sizing: border-box;
  border-bottom-color: #f0f0f0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  &.cursor-pointer:hover td {
    background-color: #fafafa;
  }
}

.virtual-table-head {
  position: sticky;
  top: 0;
  z-index: 1000;
  user-select: none;
}
.virtual-table-head table {
  width: 100%;
  margin: 0 0 -4px 0;
}
.virtual-table-head > table > thead {
  position: relative;
  width: 100%;
  background: #fafafa;
  font-size: 14px;
  color: #484848;
  margin: 0;
}

.virtual-table-head > table > thead > tr {
  display: flex;
  box-sizing: border-box;
  background: #fafafa;
}
.virtual-table-head > table > thead > tr > th {
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  background: #fafafa;
  height: 61px;
  width: 170px;
  overflow: visible;
  text-align: left;
  padding: 8px 0px 8px 12px;
  font-weight: 500;
  transition: background-color 0.15s;
  transition-timing-function: ease-in;
  .tooltip-icon {
    margin-left: 8px;
  }
  &:hover {
    background: #f2f2f2;
    color: #000;
  }
  &.th-actionable {
    width: 170px;
    display: flex;
    padding: 0;
    align-items: center;
    & .th-title {
      flex: 1 1 0%;
      padding-left: 10px;
    }
    & .th-action {
      width: 50px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: #128dff;
        svg {
          fill: #d2e9ff;
        }
      }
      svg {
        font-size: 14px;
        fill: #bfbfbf;
      }
    }
  }
  &.th-search {
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    font-size: 0.8em;
    padding: 0px;
    margin: 0px;
    .th-title-search {
      margin-left: 8px;
      margin-top: 1px;
      font-size: 0.9em;
    }
    .th-search-container {
      position: relative;
      padding: 0 4px;
      .th-search-input {
        border-radius: 24px;
        width: 100%;
        padding-left: 18px;
        font-size: 14px;
        border: 1px solid #98c6f1;
        &:active {
          border: 1px solid #4278ac;
        }
        &:focus-visible {
          border: 1px solid #4278ac;
          outline-color: none;
          outline-width: 0;
        }
      }
      .th-search-icon {
        position: absolute;
        top: 8px;
        left: 10px;
      }
      .th-clear-icon {
        position: absolute;
        top: 8px;
        right: 10px;
      }
    }
  }
}

.virtual-table > table > tbody > tr > td {
  display: block;
  box-sizing: border-box;
  background: #fff;
  width: 170px;
  overflow: visible;
  white-space: nowrap;
  &:first-child {
    padding-left: 16px;
  }
}

.virtual-table > table > tbody > tr > td > * {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 0 8px;
}
.virtual-table > table > tbody > tr > td.fixed-column {
  overflow: visible;
  z-index: 500;
  position: sticky;
}
.virtual-table th.fixed-column {
  overflow: visible;
  z-index: 1000;
  position: sticky;
}
.scrolled-x-table .fixed-column::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.fixed-column::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  content: '';
  pointer-events: none;
}

.react-resizable-handle {
  position: absolute;
  width: 1px;
  height: 100%;
  bottom: 0;
  right: 0px;
  cursor: col-resize;
  z-index: 1;
}

th:hover .react-resizable-handle {
  background: #ccc;
}

th:hover .react-resizable-handle:after,
th:hover .react-resizable-handle:before {
  content: ' ';
  display: block;
  width: 5px;
  height: 100%;
  position: absolute;
  right: 1px;
  top: 0;
}
th:hover .react-resizable-handle:after {
  right: -5px;
  width: 5px;
}

.th-vtable-title {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
