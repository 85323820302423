/*.bar {
  fill:#00abe7;
}*/

.axis {
  font: 10px sans-serif;
}

.axis path,
.axis line {
  fill: none;
  stroke: rgba(0, 0, 0, 0.54);
  shape-rendering: crispEdges;
}

.y.axis path {
  display: none;
}

.y.axis line {
  stroke: black;
  stroke-opacity: 0.03;
}

.point {
  fill: #00abe7;
  fill-opacity: 0.8;
}

.not_possible {
  fill-opacity: 0.3;
  fill: grey !important;
}

.possible {
  fill-opacity: 0.3;
  /*fill:#00abe7;*/
}

.selected {
  fill-opacity: 0.8;
  /*fill:#00abe7;*/
}

.not_selected {
  fill-opacity: 0.3;
  fill: grey !important;
}

.clicked {
  fill-opacity: 1 !important;
  stroke: black !important;
  stroke-width: 2px !important;
}

div.scatterPlotTooltip {
  position: absolute;
  border-radius: 3px;
  padding: 7px 10px;
  font-size: 12px;
  background: white;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  /*color: #fff;*/
  text-align: left;
  white-space: nowrap;
}
