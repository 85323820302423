.ody-btn {
  box-shadow: none;
  cursor: pointer;
  border: 1px solid #bdbdbd;
  padding: 8px 15px;
  max-height: 36px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  line-height: 20px;
  border-radius: 24px;
  letter-spacing: 0.5px;
  background-color: #fff;
  margin: 0 5px;
  &:hover {
    background-color: #f5f5f5;
  }
  .ody-btn-icon {
    margin-right: 8px;
  }
}
