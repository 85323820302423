@import '../../scss/styleVariables.scss';

.trailer-map-tooltip {
  font-size: 12px;
  position: absolute;
  margin: $medium-margin;
  padding: 12px !important;
  background: rgba(0, 0, 0, 0.7) !important;
  max-width: 320px;
  min-width: 130px;
  z-index: 10;
  pointer-events: none;
  border-radius: 2px;
  border: 1px solid rgb(232, 232, 232);

  * {
    color: rgba(255, 255, 255, 0.9) !important;
  }

  .trailer-title {
    font-size: 12px;
    margin-bottom: 2px;
  }
  .title {
    text-transform: uppercase;
    font-weight: lighter;
    margin-bottom: 2px;
    letter-spacing: 1.2px;
  }

  .row {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: $screen-size-tablet) {
    display: none;
  }
}
