@import '../../scss/styleVariables.scss';

.ody-tag {
  background-color: $tag-neutral-color;
  color: #000;
  padding: 4px 8px 4px 8px;
  border-radius: 13px;
  font-size: 0.8rem;
  &.tag-info {
    background-color: $tag-info-color;
  }
  &.tag-success {
    background-color: $tag-success-color;
  }
  &.tag-warning {
    background-color: $tag-warning-color;
  }
  &.tag-error {
    background-color: $tag-error-color;
  }
  &.tag-neutral {
    background-color: $tag-neutral-color;
  }
}
