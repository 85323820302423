.app-switcher.ant-popover,
.app-switcher.ant-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  padding: 0px;
}

.header-menu-right .app-sw-icon {
  top: 5px;
  margin: 0px 0px 0px 8px;
  cursor: pointer;
}
